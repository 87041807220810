import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { RedirectMirror } from "components/RedirectMirror";

ReactDOM.render(
    <BrowserRouter>
        <RedirectMirror />
    </BrowserRouter>,
    document.getElementById("root")
);
