import { withRouter } from "react-router";
import { useEffect } from "react";
import { getSubdomains } from "@sellerlabs/react-components";

const Subdomains = getSubdomains();

const RedirectMirror = withRouter(({ location }) => {
    useEffect(() => {
        window.location = `${Subdomains.PROTOCOL}://${Subdomains.MAIN_SUBDOMAIN}${location.pathname}`;
    }, []);
    return null;
});

export { RedirectMirror };
